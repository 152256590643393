
pastel = #F7E6DA
orange = #FF7648

html, body
    overflow-x hidden

body
    // font-family -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
    font-family: 'Muli', sans-serif
    font-size 16px
    font-weight 300
    color #111
    @media (max-width: 900px)
        font-size 14px

p
    margin .5em 0

h1, h2, h3
    font-family 'Amatic SC', cursive

h1
    text-align center
    font-size 3.5em
    font-weight 400
    @media (max-width: 900px)
        font-size 7vw

h2
    font-size 3em

h3
    font-size 2.5em
    color orange
    margin 3em 0 .5em
    text-align center

a
    color inherit
    &:hover
        text-decoration none

li
    list-style-type '- '

ul
    margin 0

b, h4
    font-weight 400

#site
    padding-top 150px
    @media (min-width: 900px)
        padding-top 3em

#header
    background #fff
    @media (min-width: 900px)
        position fixed
        top 0
        left 0
        right 0
        z-index 99
        height 6em
    @media (max-width: 900px)
        position absolute
        top 0
        left 0
        right 0
        z-index 99
        .up &
            position fixed

#logo
    @media (min-width: 900px)
        position absolute
        top 2em
        left 5em
    img
        height 2em
        @media (max-width: 900px)
            display block
            margin 1em auto

#menu
    text-align center
    padding 1em
    @media (min-width: 900px)
        line-height 4em
    a
        padding 2em
        color inherit
        text-decoration none
        transition all 200ms
        &:hover
            color orange
        @media (max-width: 900px)
            padding 20px 10px

    .quickContact
        @media (min-width: 900px)
            display none
        a
                display inline-block

img.chat, .stephanie
    height 300px
    margin 0 auto
    display block


section
    padding .3em
    margin 0 5px
    text-align center
    position relative

    @media (min-width: 900px)
        scroll-margin-top 3em
        padding 3em
        margin 0 50px

    &.alt
        background pastel

        img.chat
            background white
            border-radius 200px

article
    text-align left
    max-width 40em
    margin-left auto
    margin-right auto

.quote
    color orange
    font-size 1.6em
    margin-bottom 2em
    p:before
        content '«'
        padding 0 .2em
    p:after
        content '»'
        padding 0 .2em

.intro p
    margin .5em

#cat-sitter
    .intro, .quote, h2
        text-align center

#formules
    clear both

#previsite
    color #111

#veto
    @media (min-width: 900px)
        margin 2em -150px 300px
    .chat
        margin 3em auto
        @media (min-width: 900px)
            margin 0 2em
            float left

.forfait
    @media (min-width: 900px)
        padding 1em
        float right
        text-align center
        strong
            font-size 1.2em

.veto
    @media (min-width: 900px)
        margin 0 240px
    h4
        margin 1em 0 0
    ul
        margin .5em 0
        font-style italic

#engagement
    color #111

.engagement
    text-align center

#souris
    color #111
    padding-top 100px
    img 
        height 200px
        margin 10px 150px 50px

#loulous
    // &:after
    //     content ' '
    //     clear both
    //     display block

    @media (max-width: 900px)
        text-align center

    .thumb
        width 154px
        height 154px
        background-size cover
        background-repeat no-repeat
        background-color pastel
        display inline-block
        margin 0 3px
        .name
            display none
            position absolute
            bottom 0
            left 0
            right 0
            background rgba(247, 230, 218, .7)
            text-align center
            padding 5px
            font-size 9px

        &:hover
            transform scale(1.7)
            outline 2px solid white
            background-size contain
            background-position center
            .name
                display block

        @media (max-width: 900px)
            float none

.desc
    font-style italic

strong
    font-style normal !important

.chat.left
    @media (min-width: 900px)
        transform translate(-120px, 0)

.prestations2
    color orange
    font-size 1.6em
    font-style italic
    p:before
        content '«'
        padding 0 .2em
    p:after
        content '»'
        padding 0 .2em

    @media (min-width: 900px)
        width 280px
        position absolute
        left 59%
        top 380px

.path1
    position absolute
    top 100px
    left 43%
    width 600px

.prestations3
    li
        font-style italic
        font-size .9em
    @media (min-width: 900px)
        margin-top 150px

.conditions
    font-style italic
    text-align center
    margin 3em 0
    p
        margin 0
    @media (min-width: 900px)
        margin 3em 1em
    font-size .7em

.path2
    position absolute
    top 1150px
    right 60%
    width 500px
    transform rotate(28deg)


.emprunte
    width 40px
    position absolute
    @media (max-width: 900px)
        display none

.emprunte1
    top 300px
    left 74%
    transform rotate(170deg)

.emprunte2
    top 350px
    left 85%
    transform rotate(7deg) scale(-1, -1)

.emprunte3
    top 300px
    left 81%
    transform rotate(160deg)

.emprunte4
    top 360px
    left 77%
    transform rotate(4deg) scale(-1, -1)

.emprunte5
    top 200px
    left 78%
    transform rotate(170deg)

.emprunte6
    top 260px
    left 80%
    transform rotate(7deg) scale(-1, -1)

.emprunte7
    top 300px
    left 89%
    transform rotate(160deg)

.emprunte8
    top 200px
    left 86%
    transform rotate(4deg) scale(-1, -1)

.emprunte9
    top 1000px
    right 83%
    transform rotate(270deg)

.emprunte10
    top 1050px
    right 89%
    transform rotate(97deg) scale(-1, -1)

.emprunte11
    top 1110px
    right 89%
    transform rotate(260deg)

.emprunte12
    top 1050px
    right 80%
    transform rotate(94deg) scale(-1, -1)



.formules
    @media (min-width: 900px)
        display flex
        flex-direction row
        margin 20px -80px
        img
            order 99
    text-align center
    ul
        padding 0
    li
        font-weight 500
        list-style-type none
    > *
        padding .5em
        
.formules2
    color orange

.previsite
    @media (min-width: 900px)
        margin 1em 3em
    text-align center
    
.path3
    position absolute
    bottom 190px
    right 50%
    width 600px
    transform rotate(180deg)


@media (min-width: 900px)
    .split, dl
        display flex
        flex-direction row
        h2
            text-align left

    .splited
        padding 0 30px 
        flex-grow 1

#contact
    dd
        font-weight 500

    .social
        margin 0 0 0 20px
        height 35px
        
    form
        display flex
        flex-direction column

    input, textarea
        display block
        margin 2px

    textarea
        min-height 2.1em

    textarea[name="message"]
        min-height 4.2em

#envoye
    text-align center
    padding 10px
    color white
    background green
    font-weight bold
    display none
    scroll-margin-top 200px
    &:target
        display block

#erreur
    text-align center
    padding 10px
    color white
    background red
    font-weight bold
    display none
    scroll-margin-top 200px
    &:target
        display block

@media (max-width: 900px)
    .path1, .path2, .path3
        display none

.avis
    font-size .8em
    @media (min-width: 900px)
        margin 0 6em
    h4
        border-top 1px dotted rgba(0,0,0,0.2)
        padding-top 1em

#legal
    p
        text-align center
    font-size .9em
