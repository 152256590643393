html, body {
  overflow-x: hidden;
}

body {
  color: #111;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

@media (width <= 900px) {
  body {
    font-size: 14px;
  }
}

p {
  margin: .5em 0;
}

h1, h2, h3 {
  font-family: Amatic SC, cursive;
}

h1 {
  text-align: center;
  font-size: 3.5em;
  font-weight: 400;
}

@media (width <= 900px) {
  h1 {
    font-size: 7vw;
  }
}

h2 {
  font-size: 3em;
}

h3 {
  color: #ff7648;
  text-align: center;
  margin: 3em 0 .5em;
  font-size: 2.5em;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

li {
  list-style-type: "- ";
}

ul {
  margin: 0;
}

b, h4 {
  font-weight: 400;
}

#site {
  padding-top: 150px;
}

@media (width >= 900px) {
  #site {
    padding-top: 3em;
  }
}

#header {
  background: #fff;
}

@media (width >= 900px) {
  #header {
    z-index: 99;
    height: 6em;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (width <= 900px) {
  #header {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .up #header {
    position: fixed;
  }
}

@media (width >= 900px) {
  #logo {
    position: absolute;
    top: 2em;
    left: 5em;
  }
}

#logo img {
  height: 2em;
}

@media (width <= 900px) {
  #logo img {
    margin: 1em auto;
    display: block;
  }
}

#menu {
  text-align: center;
  padding: 1em;
}

@media (width >= 900px) {
  #menu {
    line-height: 4em;
  }
}

#menu a {
  color: inherit;
  padding: 2em;
  text-decoration: none;
  transition: all .2s;
}

#menu a:hover {
  color: #ff7648;
}

@media (width <= 900px) {
  #menu a {
    padding: 20px 10px;
  }
}

@media (width >= 900px) {
  #menu .quickContact {
    display: none;
  }
}

#menu .quickContact a {
  display: inline-block;
}

img.chat, .stephanie {
  height: 300px;
  margin: 0 auto;
  display: block;
}

section {
  text-align: center;
  margin: 0 5px;
  padding: .3em;
  position: relative;
}

@media (width >= 900px) {
  section {
    margin: 0 50px;
    padding: 3em;
    scroll-margin-top: 3em;
  }
}

section.alt {
  background: #f7e6da;
}

section.alt img.chat {
  background: #fff;
  border-radius: 200px;
}

article {
  text-align: left;
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;
}

.quote {
  color: #ff7648;
  margin-bottom: 2em;
  font-size: 1.6em;
}

.quote p:before {
  content: "«";
  padding: 0 .2em;
}

.quote p:after {
  content: "»";
  padding: 0 .2em;
}

.intro p {
  margin: .5em;
}

#cat-sitter .intro, #cat-sitter .quote, #cat-sitter h2 {
  text-align: center;
}

#formules {
  clear: both;
}

#previsite {
  color: #111;
}

@media (width >= 900px) {
  #veto {
    margin: 2em -150px 300px;
  }
}

#veto .chat {
  margin: 3em auto;
}

@media (width >= 900px) {
  #veto .chat {
    float: left;
    margin: 0 2em;
  }

  .forfait {
    float: right;
    text-align: center;
    padding: 1em;
  }

  .forfait strong {
    font-size: 1.2em;
  }

  .veto {
    margin: 0 240px;
  }
}

.veto h4 {
  margin: 1em 0 0;
}

.veto ul {
  margin: .5em 0;
  font-style: italic;
}

#engagement {
  color: #111;
}

.engagement {
  text-align: center;
}

#souris {
  color: #111;
  padding-top: 100px;
}

#souris img {
  height: 200px;
  margin: 10px 150px 50px;
}

@media (width <= 900px) {
  #loulous {
    text-align: center;
  }
}

#loulous .thumb {
  background-color: #f7e6da;
  background-repeat: no-repeat;
  background-size: cover;
  width: 154px;
  height: 154px;
  margin: 0 3px;
  display: inline-block;
}

#loulous .thumb .name {
  text-align: center;
  background: #f7e6dab3;
  padding: 5px;
  font-size: 9px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#loulous .thumb:hover {
  background-position: center;
  background-size: contain;
  outline: 2px solid #fff;
  transform: scale(1.7);
}

#loulous .thumb:hover .name {
  display: block;
}

@media (width <= 900px) {
  #loulous .thumb {
    float: none;
  }
}

.desc {
  font-style: italic;
}

strong {
  font-style: normal !important;
}

@media (width >= 900px) {
  .chat.left {
    transform: translate(-120px);
  }
}

.prestations2 {
  color: #ff7648;
  font-size: 1.6em;
  font-style: italic;
}

.prestations2 p:before {
  content: "«";
  padding: 0 .2em;
}

.prestations2 p:after {
  content: "»";
  padding: 0 .2em;
}

@media (width >= 900px) {
  .prestations2 {
    width: 280px;
    position: absolute;
    top: 380px;
    left: 59%;
  }
}

.path1 {
  width: 600px;
  position: absolute;
  top: 100px;
  left: 43%;
}

.prestations3 li {
  font-size: .9em;
  font-style: italic;
}

@media (width >= 900px) {
  .prestations3 {
    margin-top: 150px;
  }
}

.conditions {
  text-align: center;
  margin: 3em 0;
  font-size: .7em;
  font-style: italic;
}

.conditions p {
  margin: 0;
}

@media (width >= 900px) {
  .conditions {
    margin: 3em 1em;
  }
}

.path2 {
  width: 500px;
  position: absolute;
  top: 1150px;
  right: 60%;
  transform: rotate(28deg);
}

.emprunte {
  width: 40px;
  position: absolute;
}

@media (width <= 900px) {
  .emprunte {
    display: none;
  }
}

.emprunte1 {
  top: 300px;
  left: 74%;
  transform: rotate(170deg);
}

.emprunte2 {
  top: 350px;
  left: 85%;
  transform: rotate(7deg)scale(-1);
}

.emprunte3 {
  top: 300px;
  left: 81%;
  transform: rotate(160deg);
}

.emprunte4 {
  top: 360px;
  left: 77%;
  transform: rotate(4deg)scale(-1);
}

.emprunte5 {
  top: 200px;
  left: 78%;
  transform: rotate(170deg);
}

.emprunte6 {
  top: 260px;
  left: 80%;
  transform: rotate(7deg)scale(-1);
}

.emprunte7 {
  top: 300px;
  left: 89%;
  transform: rotate(160deg);
}

.emprunte8 {
  top: 200px;
  left: 86%;
  transform: rotate(4deg)scale(-1);
}

.emprunte9 {
  top: 1000px;
  right: 83%;
  transform: rotate(270deg);
}

.emprunte10 {
  top: 1050px;
  right: 89%;
  transform: rotate(97deg)scale(-1);
}

.emprunte11 {
  top: 1110px;
  right: 89%;
  transform: rotate(260deg);
}

.emprunte12 {
  top: 1050px;
  right: 80%;
  transform: rotate(94deg)scale(-1);
}

.formules {
  text-align: center;
}

@media (width >= 900px) {
  .formules {
    flex-direction: row;
    margin: 20px -80px;
    display: flex;
  }

  .formules img {
    order: 99;
  }
}

.formules ul {
  padding: 0;
}

.formules li {
  font-weight: 500;
  list-style-type: none;
}

.formules > * {
  padding: .5em;
}

.formules2 {
  color: #ff7648;
}

.previsite {
  text-align: center;
}

@media (width >= 900px) {
  .previsite {
    margin: 1em 3em;
  }
}

.path3 {
  width: 600px;
  position: absolute;
  bottom: 190px;
  right: 50%;
  transform: rotate(180deg);
}

@media (width >= 900px) {
  .split, dl {
    flex-direction: row;
    display: flex;
  }

  .split h2, dl h2 {
    text-align: left;
  }

  .splited {
    flex-grow: 1;
    padding: 0 30px;
  }
}

#contact dd {
  font-weight: 500;
}

#contact .social {
  height: 35px;
  margin: 0 0 0 20px;
}

#contact form {
  flex-direction: column;
  display: flex;
}

#contact input, #contact textarea {
  margin: 2px;
  display: block;
}

#contact textarea {
  min-height: 2.1em;
}

#contact textarea[name="message"] {
  min-height: 4.2em;
}

#envoye {
  text-align: center;
  color: #fff;
  background: green;
  padding: 10px;
  scroll-margin-top: 200px;
  font-weight: bold;
  display: none;
}

#envoye:target {
  display: block;
}

#erreur {
  text-align: center;
  color: #fff;
  background: red;
  padding: 10px;
  scroll-margin-top: 200px;
  font-weight: bold;
  display: none;
}

#erreur:target {
  display: block;
}

@media (width <= 900px) {
  .path1, .path2, .path3 {
    display: none;
  }
}

.avis {
  font-size: .8em;
}

@media (width >= 900px) {
  .avis {
    margin: 0 6em;
  }
}

.avis h4 {
  border-top: 1px dotted #0003;
  padding-top: 1em;
}

#legal {
  font-size: .9em;
}

#legal p {
  text-align: center;
}
/*# sourceMappingURL=index.eeb2cf3d.css.map */
